<template>
	<div class="mendian-page">
		<div class="mendian-page-header">
			<div class="main flex alcenter space">
				<div class="flex alcenter">
					<img style="width: 280px; height: 40px;" :src=" siteInfo && siteInfo.logo_white_account_mendian" />
					<span class="title">商户管理中心</span>
				</div>
				<div class="flex alcenter">

					<span class="ft14 cl-w mr10">欢迎您，{{account.username}}</span>
					<span class="password" @click="showPwd = true">修改密码</span>
					<span class="log-out ml5" @click="loginOut()">退出</span>
				</div>
			</div>
		</div>
		
		<div class="mt40" style="flex: auto;">
			<div class="mendian-page-main">
				<router-view></router-view>
			</div>
		</div>
		<!-- ©2012-{{getNowYear}} {{siteInfo ? siteInfo.domain : ''}} -->
		<div class="mendian-footer text-center pd24_20 ft14 cl-info"> </div>

		<a-modal v-model="showPwd" title="设置密码" on-ok="handleOk">
			<template slot="footer">
				<a-button key="back" @click="handlePwdCancel">
					取消
				</a-button>
				<a-button key="submit" type="primary" :loading="pwdloading" @click="handlePwdOk">
					确认
				</a-button>
			</template>
			<a-form-model :model="pwd" :label-col="labelCol" :wrapper-col="wrapperCol">
				<a-form-model-item label="输入密码">
					<a-input type="password" v-model="pwd.pwd" />
				</a-form-model-item>
				<a-form-model-item label="再次确认">
					<a-input type="password"  v-model="pwd.repwd" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>

	</div>
</template>

<script>
	export default{
		data(){
			return {
				pwd:{
					pwd:'',
					repwd:'',
				},
				labelCol: { span: 4 },
				wrapperCol: { span: 14 },
				pwdloading:false,
				showPwd:false,
				account:{
					mobile:'',
					user_name:'',
					face:'',
				},
				siteInfo:null,
			}
		},
		computed:{
			getNowYear(){
				let date = new Date();
				return date.getFullYear(); 
			}
		},
		created(){
			this.showTime();
			this.getSiteInfo();
			this.getLayoutData();
			
		},
		methods:{
			handlePwdCancel(){
				this.showPwd = false;
			},
			handlePwdOk(){
				if(this.pwdloading == true) return;
				this.pwdloading = true;
				this.$http.api('platform/account/editPwd',{
					pwd:this.pwd.pwd,
					repwd:this.pwd.repwd
				}).then(res=>{
					this.pwdloading = false;
					this.$message.success('密码设置成功');
					this.showPwd = false;
				}).catch(res=>{
					this.pwdloading = false;
				});
			},
			getSiteInfo(){
				this.$http.api('platform/siteInfo').then(res=>{
					console.log(res);
					this.siteInfo = res.site;
					
				}).catch(res=>{
					console.log(res);
				});
			},
			showTime(){
				var now = new Date()
				var hour = now.getHours()
				var t = ''
				if (hour < 6) {
				  t = '凌晨好'
				} else if (hour < 9) {
				  t = '早上好'
				} else if (hour < 12) {
				  t = '上午好'
				} else if (hour < 14) {
				  t = '中午好'
				} else if (hour < 17) {
				  t = '下午好'
				} else if (hour < 19) {
				  t = '傍晚好'
				} else if (hour < 22) {
				  t = '晚上好'
				} else {
				  t = '夜里好'
				}
				this.showT = t;
			},
			
			getLayoutData(){
				this.$http.api('platform/account/layout').then(res=>{
					this.account=res.account;
				}).catch(res=>{
					console.log(res);
				})
			},
			
			loginOut(){
				localStorage.removeItem('account-token');
				this.$router.push('/');
			}
		}
	}
</script>

<style>
	.mendian-footer{
		    flex: 0 0 auto;
	}
	.mendian-list{
		display: grid;
		grid-template-columns: repeat(3,380px);   
		/* grid-template-rows: repeat(3,100px);  */
		grid-column-gap: 160px;
		grid-row-gap: 40px;
	}
	.mendian-item{
		width: 520px;
		height: 120px;
		background: #FFFFFF;
		border-radius: 8px;
		/*border-left: 8px solid #EBEDF5;*/
		padding: 20px 16px;
		cursor: pointer;
		transition: all 0.3s;
	}
	.mendian-item .tag{
		width: 52px;
		height: 20px;
		border-radius: 10px;
		border: 1px solid #4772FF;
		text-align: center;
		line-height: 18px;
		font-size: 12px;
		color:#4772FF;
	}
	.mendian-item:hover{
		transform: translateY(-10px);
	}
	.mendian-item.add{
		border: 1px dashed #4772FF;
	}
	.mendian-page{
		min-width: 1200px;
		min-height: 100vh;
		display: flex;
		flex: auto;
		flex-direction: column;
	}
	.mendian-page-header{
		width: 100%;
		height: 60px;
		background: #4772FF;
		flex: 0 0 auto;
	}
	.mendian-page-header .main{
		width: 1600px;
		/* padding: 0 150px; */
		height: 60px;
		margin: 0 auto;
	}
	.mendian-page-header .log-out{
		width: 44px;
		border-radius: 10px;
		border: 1px solid #FFFFFF;
		text-align: center;
		line-height: 22px;
		font-size: 14px;
		color:#FFFFFF;
		cursor: pointer;
	}
	.mendian-page-header .password{
		width: 88px;
		border-radius: 12px;
		border: 1px solid #FFFFFF;
		text-align: center;
		line-height: 22px;
		font-size: 14px;
		color:#FFFFFF;
		cursor: pointer;
	}
	.mendian-page-header .title{
		font-size: 20px;
		font-weight: 600;
		color:#FFFFFF;
		padding-left: 12px;
		border-left: 1px solid #FFFFFF;
		margin-left: 12px;
		height: 20px;
		line-height: 20px;
	}
	.mendian-page-main{
		width: 1600px;
		margin: 0 auto;
		
	}
</style>